import React from 'react';
import styled from 'styled-components';

const PurpleArrowDetail = () => (
  <Svg width="68" height="94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 82.226v10h10M2 12.226v-10h10" stroke="#fff" strokeWidth="3" />
    <rect x="36" y="36.226" width="32" height="32" fill="#6B3CAD" />
    <path
      d="M40.6 29.826l22.3 22.3-22.3 22.298"
      stroke="#fff"
      strokeWidth="3"
    />
  </Svg>
);

const Svg = styled.svg`
  margin-right: 36px;
`;

export default PurpleArrowDetail;
