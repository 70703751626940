import React from 'react';
import styled from 'styled-components';

const DotDetail = () => (
  <svg width="88" height="88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFF300">
      <rect width="4" height="4" />
      <rect x="12" width="4" height="4" />
      <rect x="24" width="4" height="4" />
      <rect x="36" width="4" height="4" />
      <rect x="48" width="4" height="4" />
      <rect x="60" width="4" height="4" />
      <rect x="72" width="4" height="4" />
      <rect y="12" width="4" height="4" />
      <rect x="12" y="12" width="4" height="4" />
      <rect x="24" y="12" width="4" height="4" />
      <rect x="36" y="12" width="4" height="4" />
      <rect x="48" y="12" width="4" height="4" />
      <rect x="60" y="12" width="4" height="4" />
      <rect y="24" width="4" height="4" />
      <rect x="12" y="24" width="4" height="4" />
      <rect x="24" y="24" width="4" height="4" />
      <rect x="36" y="24" width="4" height="4" />
      <rect x="48" y="24" width="4" height="4" />
      <rect y="36" width="4" height="4" />
      <rect x="12" y="36" width="4" height="4" />
      <rect x="24" y="36" width="4" height="4" />
      <rect x="36" y="36" width="4" height="4" />
      <rect y="48" width="4" height="4" />
      <rect x="12" y="48" width="4" height="4" />
      <rect x="24" y="48" width="4" height="4" />
      <rect y="60" width="4" height="4" />
      <rect x="12" y="60" width="4" height="4" />
      <rect y="72" width="4" height="4" />
      <rect y="84" width="4" height="4" />
      <rect x="12" y="72" width="4" height="4" />
      <rect x="24" y="60" width="4" height="4" />
      <rect x="36" y="48" width="4" height="4" />
      <rect x="48" y="36" width="4" height="4" />
      <rect x="60" y="24" width="4" height="4" />
      <rect x="72" y="12" width="4" height="4" />
      <rect x="84" width="4" height="4" />
    </g>
  </svg>
);

const Svg = styled.svg`
  margin-right: 36px;
`;

export default DotDetail;
