import React, { Component } from 'react';
import styled from 'styled-components';

// styled
import Section from '../styled/Section';
import Superscript from '../styled/Superscript';
import Wrapper from '../styled/Wrapper';
import DotDetail from '../styled/DotDetail';
import PurpleArrowDetail from '../styled/PurpleArrowDetail';

const usdCadRate = 1.33;

const cadToUsd = (cad) => {
  return Math.ceil(cad / usdCadRate);
}

const eventBriteLink = 'https://www.eventbrite.com/e/assertjs-2019-the-js-testing-conference-tickets-61173120450#tickets';

const TicketDetails = [
  {
    label: 'Conference',
    early: {
      cad: 250, usd: function () { return cadToUsd(this.cad) }, enabled: false
    },
    standard: { cad: 325, usd: function () { return cadToUsd(this.cad) }, enabled: false },
    late: { cad: 375, usd: function () { return cadToUsd(this.cad) }, enabled: true },
  },
  {
    label: 'Conference + Training',
    early: { cad: 750, usd: function () { return cadToUsd(this.cad) }, enabled: false },
    standard: { cad: 825, usd: function () { return cadToUsd(this.cad) }, enabled: false },
    late: { cad: 875, usd: function () { return cadToUsd(this.cad) }, enabled: true },
  },
];

const Tickets = () => (
  <Section center noPaddingTop>
    <Wrapper>
      <Superscript center>/* Tickets */</Superscript>
      <Smallscript>
        Hurry, standard tickets end August 31!
      </Smallscript>
      <TicketsContainer>
        {TicketDetails.map(({ label, early, standard, late }, i) => (
          <Ticket key={i} >
            <ArrowLabel>
              <PurpleArrowDetail />
              <Label>{label}</Label>
            </ArrowLabel>
            <Prices>
              <Price text="Early" enabled={early.enabled} href={eventBriteLink} target="_blank">
                CA${early.cad} (US${early.usd()}*)
                {early.enabled ? <DotDetail /> : null}
              </Price>
              <Price text="Standard" enabled={standard.enabled} href={eventBriteLink} target="_blank">
                CA${standard.cad} (US${standard.usd()}*)
                {standard.enabled ? <DotDetail /> : null}
              </Price>
              <Price text="Late" enabled={late.enabled} href={eventBriteLink} target="_blank">
                CA${late.cad} (US${late.usd()}*)
                {late.enabled ? <DotDetail /> : null}
              </Price>
            </Prices>
          </Ticket>
        ))}
      </TicketsContainer>
      <Smallscript>
        * US dollar conversion rate is based on a rate of 1 CAD = {usdCadRate} USD, check the current rate <a href="https://www.google.com/search?q=usd+in+cad">here</a>.
      </Smallscript>
    </Wrapper>
  </Section>
);

const Smallscript = styled.div`
  color: #fff300;
  margin-top: -32px;
  margin-bottom: 60px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  a {
    color: #fff300;
  }
`;

const TicketsContainer = styled.div`
  margin-bottom: 80px;
  @media (max-width: 680px) {
    padding: 0 12px;
  }
`;

const ArrowLabel = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
  }
`;

const Prices = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const Ticket = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  margin-bottom: 24px;
  @media (max-width: 800px) {
    display: block;
    margin-bottom: 60px;
  }
`;

const Label = styled.div`
  height: 94px;
  border: 3px solid white;
  width: 260px;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    flex-grow: 1;
    width: auto;
    padding: 0 12px;
  }
`;

const Price = styled.a`
  color: white;
  text-decoration: none;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  width: 160px;
  border: 3px solid white;
  margin-left: 24px;
  position: relative;
  padding: 12px 24px 0;
  font-weight: bold;
  border-color: #fff300;
  ${props =>
    !props.enabled &&
    `opacity: 0.3; pointer-events: none; border-color: white;`}
  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 20px;
    width: auto;
    flex-grow: 1;
    padding: 12px 12px 0;
    margin-left: 12px;
    &:first-child {
      margin-left: 0;
    }
  }
  svg {
    position: absolute;
    top: 0;
    left: 2px;
    opacity: 0.3;
    z-index: -1;
  }
  ${props =>
    props.text &&
    `
  ::before {
    content: "${props.text}";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 10px;
    text-transform: uppercase;
    background-color: ${props.enabled ? `#fff300` : `white`};
    color: black;
    padding: 0 6px;
    line-height: 14px;
    font-weight: bold;
  }
  `}
`;

export default Tickets;
