import React from 'react';
// import { Link } from 'gatsby';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MailingList from '../components/sections/MailingList';
import Tickets from '../components/sections/Tickets'

// styled
import Superscript from '../components/styled/Superscript';
import Section from '../components/styled/Section';
import Container from '../components/styled/Container';
import Wrapper from '../components/styled/Wrapper';
import Row from '../components/styled/Row';
import Column from '../components/styled/Column';

class SignUp extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Tickets" />
        <Nav selected="/tickets" />
        <Header />

        <Tickets />

        <MailingList />

        <Footer />
      </Container>
    );
  }
}

export default SignUp;
